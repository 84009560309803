import styled, { css } from "styled-components";

import GlobalContainer from "../GlobalContainer";
import { palette } from "modules/defines/styles";
import {
  ContentsContainer,
  ContentsLayout,
  PageTitle,
  TopWrapper,
} from "components/GlobalStyles";
import { Button34b } from "components/common/Button";
import { STATUS_TEXT_KOR } from "modules/defines/define";
import InfoSummary from "components/common/InfoSummary";
import { apiPaths } from "modules/defines/path";
import { apiAWS, productAPI } from "api";
import { useContext, useEffect, useState } from "react";
import { ProductItem } from "modules/defines/interfaces";
import { useQueryClient } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { ModalContext, ModalContextType } from "modules/context/ModalContext";
import ModalOrderList from "components/modal/ModalOrderList";
import { CellButton } from "components/common/TextField";
import { formatDateToKST } from "utils/util";
import LoadingModal from "components/ui/state/LoadingModal";
import { body14 } from "components/style/body";
import { titleContents, titleText } from "components/style/title";
import { subtitle14 } from "components/style/subtitle";
import { useHistory, useLocation } from "react-router-dom";
import { m } from "framer-motion";
import ModalStoreDelay from "components/modal/ModalStoreDelay";

const ProductListContainer: React.FC = () => {
  const statusSummary = [-1, 3, 4, 1, 2, 5, 6]; // summary 에 보여줄 status 순서 (-1은 전체)
  const [productList, setProductList] = useState<ProductItem[]>([]);
  const history = useHistory();
  const [productCount, setProductCount] = useState<number[]>([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [cookies, setCookie] = useCookies();
  const [modal, setModal] = useState<boolean>(false);
  const {
    openModal,
    closeModal,
    modal: portalModal,
  } = useContext(ModalContext) as ModalContextType;
  const currentLocation = useLocation();
  // console.log(currentLocation);

  useEffect(() => {
    return openModal(
      <ModalStoreDelay
        subject={"서비스 재정비 안내"}
        onClose={() => closeModal()}
      />
    );
  }, []);

  const statusTitle = statusSummary.map((statusIdx) =>
    statusIdx === -1 ? "전체" : STATUS_TEXT_KOR[statusIdx].title
  );

  const queryClient = useQueryClient();

  const getDashboardManagerQuery = queryClient.getQueryData([
    "dashboardManager",
  ]) as {} as any | undefined;

  const ManagerData = getDashboardManagerQuery?.data.data.manager;
  // [ Data initialize ]
  useEffect(() => {
    if (ManagerData) {
      setModal(true);
      productAPI
        .getProductList(ManagerData.id)
        .then((res) => {
          setProductList([]);
          setProductCount([0, 0, 0, 0, 0, 0, 0]);
          res.data.map((item: ProductItem, idx: number) => {
            if (cookies.isAdmin === false || cookies.isAdmin === undefined) {
              if (item.status === 4 || item.status === 5) {
                setProductList((prev) => [...prev, item]);
              }
            } else {
              setProductList((prev) => [...prev, item]);
            }
            setProductCount((prev) => {
              const newProductCount = [...prev];
              newProductCount[0] += 1;
              if (item.status === 3) newProductCount[1] += 1; // 판매 대기
              else if (item.status === 4) newProductCount[2] += 1; // 판매 중
              else if (item.status === 1) newProductCount[3] += 1; // 승인 대기
              else if (item.status === 2) newProductCount[4] += 1; // 승인 취소
              else if (item.status === 5) newProductCount[5] += 1; // 판매 중지
              else if (item.status === 6) newProductCount[6] += 1; // 판매 종료
              return newProductCount;
            });
          });
        })
        .finally(() => {
          setModal(false);
        });
    }
  }, [ManagerData]);

  const params = new URLSearchParams(currentLocation.search);
  useEffect(() => {
    if (portalModal === false) {
      history.push({ search: "" });
    }
  }, [portalModal]);

  const handleProductRegister = async () => {
    if (cookies.isAdmin === false || cookies.isAdmin === undefined) {
      alert("관리자 권한이 필요합니다.");
    } else {
      if (window.confirm("새로운 상품을 등록하시겠습니까?")) {
        const postData = {
          manager_id: ManagerData.id,
        };
        await productAPI.registerProduct(postData).then((res) => {
          if (res.status === 200) {
            alert("상품이 등록되었습니다.");
            return (window.location.href = `/product/${res.data.product.productId}-r`);
          } else {
            alert(
              "상품이 등록 중 오류가 발생했습니다.\n관리자에게 문의 바랍니다."
            );
          }
        });
      }
    }
  };

  // 판매내역 버튼
  const handlePurchaseListBtn = (
    e: any,
    productID: string,
    productOptionNotExist: boolean
  ) => {
    e.stopPropagation();
    params.set("filter", "");
    history.push({ search: params.toString() });
    return openModal(
      <ModalOrderList
        subject="판매 내역"
        description={`해당 상품에 대한 판매 내역입니다. 사용승인 버튼을 통해 구매에 대한 사용 승인을 해주세요.`}
        productId={productID}
        productOptionNotExist={productOptionNotExist}
      />
    );
  };

  return (
    <>
      <GlobalContainer>
        {modal && <LoadingModal />}
        <ContentsContainer>
          <TopWrapper>
            <PageTitle>상품 조회/관리</PageTitle>
            <Button34b btype="primary" onClick={handleProductRegister}>
              상품 등록
            </Button34b>
          </TopWrapper>
          <InfoSummary title={statusTitle} value={productCount} type="number" />
          <ContentsLayout marginTop={"16px"}>
            {productList.length === 0 ? (
              <ProductItemEmptyWrapper>
                <ProductItemEmptyTitle>
                  등록된 상품이 없습니다.
                </ProductItemEmptyTitle>
                <ProductItemEmptyDesc>
                  ‘상품 조회/관리 {">"} 상품 등록’ 에서
                  <br />
                  상품을 등록해주세요.
                </ProductItemEmptyDesc>
              </ProductItemEmptyWrapper>
            ) : (
              <table>
                <Thead>
                  <tr>
                    <Th>상품번호</Th>
                    <Th>상품명</Th>
                    {ManagerData.id === 1 && (
                      <>
                        <Th>공간</Th>
                        <Th>매니저ID</Th>
                      </>
                    )}
                    <Th>카테고리</Th>
                    <Th>상태</Th>
                    <Th>가격</Th>
                    <Th>할인가</Th>
                    <Th>옵션</Th>
                    <Th>등록일</Th>
                    <Th>수정일</Th>
                    <Th>판매내역</Th>
                  </tr>
                </Thead>
                <Tbody>
                  {productList.map((product) => {
                    const createdDate = formatDateToKST(
                      new Date(product.createdAt)
                    )
                      .split("T")[0]
                      .substring(2)
                      .replace(/-/g, "/");
                    const updatedDate = formatDateToKST(
                      new Date(product.updatedAt)
                    )
                      .split("T")[0]
                      .substring(2)
                      .replace(/-/g, "/");
                    return (
                      <Tr
                        key={product.id}
                        onClick={() =>
                          (window.location.href = `/product/${product.productId}`)
                        }
                      >
                        <Td align={"center"} noPadding>
                          {product.productId}
                        </Td>
                        <Td align={"left"}>{product.title}</Td>
                        {ManagerData.id === 1 && (
                          <>
                            <Td>{product.spaceTitle}</Td>
                            <Td>{product.manager_id}</Td>
                          </>
                        )}
                        <Td noPadding>{product.category}</Td>
                        {/* <Td color={STATUS_TEXT_KOR[product.status].textColor}>
                          {STATUS_TEXT_KOR[product.status].title}
                        </Td> */}
                        <Td align={"center"} noPadding>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <StatusBadge
                              color={STATUS_TEXT_KOR[product.status].color}
                            >
                              {STATUS_TEXT_KOR[product.status].title}
                            </StatusBadge>
                          </div>
                        </Td>
                        <Td align={"right"}>
                          {(product.price * 1).toLocaleString()}원
                        </Td>
                        <Td align={"right"}>
                          {(product.priceFinal * 1).toLocaleString()}원
                        </Td>
                        <Td>
                          {product.options === null ||
                          product.options.length === 0
                            ? "N"
                            : "Y"}
                        </Td>
                        <Td>{createdDate}</Td>
                        <Td>{updatedDate.split("T")[0]}</Td>

                        <Td noPadding>
                          <CellButton
                            type="tertiary"
                            width="100%"
                            onClick={(e) =>
                              handlePurchaseListBtn(
                                e,
                                product.productId,
                                product.options === null ||
                                  product.options.length === 0
                              )
                            }
                          >
                            보기
                          </CellButton>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </table>
            )}
          </ContentsLayout>
        </ContentsContainer>
      </GlobalContainer>
    </>
  );
};

const Thead = styled.thead`
  background-color: ${palette.neutral200};
`;
const Th = styled.th`
  border: 1px solid ${palette.neutral300};
  color: ${palette.neutral800};
  text-align: center;
  ${titleText};
  padding: 10px 8px;
`;
const Tbody = styled.tbody`
  height: 100%;
`;

const Tr = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: ${palette.neutral100};
  }
`;
const Td = styled.td<{
  align?: string;
  color?: string;
  noPadding?: boolean;
}>`
  border: 1px solid ${palette.neutral300};
  color: ${(props) => (props.color ? props.color : palette.neutral800)};
  text-align: center;
  padding: ${(props) => (props.noPadding ? "0px" : "10px 8px")};
  text-align: center;
  text-align: ${(props) => props.align === "center" && "center"};
  text-align: ${(props) => props.align === "left" && "left"};
  text-align: ${(props) => props.align === "right" && "right"};
  vertical-align: middle;
  margin: 0 auto;

  ${subtitle14};
`;
const ProductItemEmptyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-bottom: 60px;
`;
const ProductItemEmptyTitle = styled.div`
  color: ${palette.neutral500};
  text-align: center;
  ${titleContents};
`;
const ProductItemEmptyDesc = styled.div`
  display: flex;
  text-align: center;
  color: ${palette.neutral500};
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  ${body14};
`;

// 임시 : 판매내역 버튼
const OrderListBtn = styled.button`
  padding: 4px 8px;
  background-color: ${palette.neutral500};
  &:hover {
    background-color: ${palette.primary500};
  }
  color: ${palette.neutral100};
  border-radius: 4px;
`;

const StatusBadge = styled.div<{ color?: string }>`
  padding: 2px 8px;

  text-align: center;

  ${titleText};

  border-radius: 20px;

  ${(props) =>
    props.color === "gray" &&
    css`
      color: ${palette.neutral500};
      background-color: ${palette.neutral200};
    `}
  ${(props) =>
    props.color === "primary" &&
    css`
      color: ${palette.primary500};
      background-color: ${palette.primary20};
    `}
  ${(props) =>
    props.color === "red" &&
    css`
      color: ${palette.secondary400};
      background-color: ${palette.secondary20};
    `}
  ${(props) =>
    props.color === "accent" &&
    css`
      color: ${palette.white100};
      background-color: ${palette.secondary400};
    `}
  
  width: 80px;
`;

export default ProductListContainer;

import styled from "styled-components";
import { ModalStandardProps } from "modules/defines/interfaces";
import { motion } from "framer-motion";
import { palette } from "modules/defines/styles";
import { ButtonSolid48, ButtonTextSolid } from "components/common/Button";

const ModalStoreDelay = ({ ...props }: ModalStandardProps) => {
  const { subject, description, onSuccess, onClose } = props;
  return (
    <ModalWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.5,
      }}
    >
      <TitleWrapper>
        <Title>{subject}</Title>
        <Description>
          안녕하세요, FAV Store입니다.
          <br />
          현재 FAV Store는 서비스 재정비를 위해 잠시 운영을 중단하고 있으며, 4월
          중으로 새롭게 오픈할 예정입니다.
          <br />
          이용에 불편을 드려 진심으로 사과드리며, 조금만 더 기다려 주시면 더
          나은 서비스와 모습으로 찾아뵙겠습니다.
        </Description>
      </TitleWrapper>
      <ButtonWrapper>
        <ButtonSolid48 btype="primary" onClick={onClose} width="100%">
          확인
        </ButtonSolid48>
      </ButtonWrapper>
    </ModalWrapper>
  );
};
const ModalWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 420px;
  padding: 12px 4px;
  margin: 20px;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Title = styled.div`
  color: ${palette.neutral1000};
  font-feature-settings: "clig" off, "liga" off;

  /* TitleContents */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
`;
const Description = styled.div`
  color: ${palette.neutral600};
  font-feature-settings: "clig" off, "liga" off;

  /* body14 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

const InputText = styled.textarea`
  //   all: unset;
  color: ${palette.neutral1000};
  //   font-feature-settings: "clig" off, "liga" off;

  display: flex;
  padding: 10px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${palette.neutral200};
  margin-top: 12px;

  /* Subtitle14 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  height: 134px;
`;
const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  box-sizing: border-box;
  width: 100%;
`;
export default ModalStoreDelay;
